import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDHMbZP09IAhFj-CVOCvE5u4DD9Bz9W97E",
  authDomain: "special-expert-trading.firebaseapp.com",
  projectId: "special-expert-trading",
  storageBucket: "special-expert-trading.firebasestorage.app",
  messagingSenderId: "337722956525",
  appId: "1:337722956525:web:dacb330f1e6b74e8c28ae4",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDtmNsjSnruKS6ZCs3jwqec0QMqGqGKQWk",
//   authDomain: "finance-v2-e1682.firebaseapp.com",
//   projectId: "finance-v2-e1682",
//   storageBucket: "finance-v2-e1682.appspot.com",
//   messagingSenderId: "133386766551",
//   appId: "1:133386766551:web:e0236afa4a4dc881e20892",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
